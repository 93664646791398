import './App.css';
import checkbox from './images/checkbox.png'
import radio from './images/radio.png'
import chip from './images/chip.png'
import inputCard from './images/inputCard.png'
import placeholder from './images/placeholder.svg'

function CourseLevelTopicCardImage(props) {
  
  const images = [[radio, checkbox, chip, inputCard, placeholder, placeholder, placeholder, placeholder], [placeholder, placeholder, placeholder, placeholder, placeholder, placeholder], [placeholder, placeholder, placeholder, placeholder, placeholder, placeholder, placeholder], [placeholder, placeholder, placeholder, placeholder, placeholder, placeholder, placeholder]]
  const image = images[props.level][props.topicIndex]
  const margin = props.imageMargin ? props.imageMargin : '11px 0 0 12px';
  const maxWidth = props.imageMaxWidth ? props.imageMaxWidth : '120px';

  return (
      <img 
        className="CourseLevelTopicCardImage" 
        src={image} style={{margin: margin, maxWidth: maxWidth}} 
        alt={props.name}
      />
  );
}

export default CourseLevelTopicCardImage;