import React from 'react';
import ActivityAnswersCard from './ActivityAnswersCard';
import './App.css';

function ActivityAnswers(props) {
  const selection = props.data.type === "multiSelect" ? props.multiSelections : props.selectedAnswer
  return (
    <div className={props.data.grid ? "ActivityAnswers-row" : null}>
      {props.options.map((option, index) => {
        return <ActivityAnswersCard 
                 option={option}
                 index={index}
                 data={props.data}
                 lesson={props.lesson}
                 topic={props.topic}
                 selectAnswer={props.selectAnswer}
                 activity={props.activity}
                 selected={props.data.type === "multiSelect" ? selection[index] : (selection === index)}
               />
      })}
    </div>
  );
}

export default ActivityAnswers;
