import React from 'react';
import success1 from './images/Success1.jpg'
import success2 from './images/Success2.jpg'
import success3 from './images/success3.jpg'
import success4 from './images/Success4.jpg'
import ActivityProgressBar from './ActivityProgressBar';
import Button from './Button'
import './App.css';

function LessonSuccess(props) {
  const radioImages = [success1, success2, success3]
  const checkboxImages = [success4]
  const images = [radioImages, checkboxImages]
  const RadioButtonText = ["Lesson 2: Microcopy", "Lesson 3: Behavior"]
  const CheckboxButtonText = ["Lesson 2: Groups"]
  const buttonText = [RadioButtonText, CheckboxButtonText]
  const finalLesson = (props.lesson === (props.lessonTotal - 1))
  return (
    <div className="LessonSuccess">
      <ActivityProgressBar activity={props.activity} closeLesson={props.closeLesson} />
      <img className="LessonSuccess-image" src={images[props.topic][props.lesson]} alt="Celebration message" />
      {finalLesson ? <p>A new lesson will be added every Tuesday!</p> : null}
      <p>We’d love to hear your feedback. Drop us a line at hello@learnwithmerlin.com</p>
      <Button 
        text={finalLesson ? "Back" : buttonText[props.topic][props.lesson]}
        continue={finalLesson ? props.closeLesson : props.nextLesson}
      />
    </div>
  );
}

export default LessonSuccess;
