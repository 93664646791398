import React from 'react';
import Tool from './Tool.js';
import './App.css';
import fill from './images/fill.svg'
import stroke from './images/stroke.svg'
import radius from './images/radius.svg'
import shadow from './images/shadow.svg'
import submit from './images/submit.svg'
import margin from './images/margin.svg'
import marginV from './images/marginV.svg'
import marginH from './images/marginH.svg'
import padding from './images/padding.svg'
import width from './images/width.svg'
import vertical from './images/vertical.svg'
import horizontal from './images/horizontal.svg'
import left from './images/left.svg'
import right from './images/right.svg'
import top from './images/top.svg'
import bottom from './images/bottom.svg'
import centerH from './images/centerH.svg'
import centerV from './images/centerV.svg'


function Toolbar(props) {
  const tools = props.data.tools;
  const icons = tools.map(showIcon);
  function showIcon(tool) {
    switch(tool) {
      case "Fill":
        return fill;
      case "Stroke":
          return stroke;
      case "Radius":
        return radius;
      case "Shadow":
          return shadow;
      case "Margin":
        return margin;
      case "V Margin":
        return marginV;
      case "H Margin":
        return marginH;
      case "Padding":
          return padding;
      case "Width":
        return width;
      case "Vertical":
        return vertical;
      case "Horiz":
        return horizontal;
      case "Left":
        return left;
      case "Center":
        return centerH;
      case "Right":
          return right;
      case "Top":
        return top;
      case "Bottom":
        return bottom;
      case "Direction":
        return horizontal;
      case "Align":
        return centerH;
      case "V Align":
        return centerV;
      default:
        return submit
    }
  }

  return (
    <div className="Toolbar Toolbar-appear">
      <div className="Toolbar-content">
        {tools.map((tool, index) => {
          return <Tool
                    name={tool}
                    icon={icons[index]}
                    showTool={props.showTool}
                    index={index}
                    submit={props.submit}
                    updateDirection={props.updateDirection}
                    updateAlignment={props.updateAlignment}
                    fill={props.fill}
                    selected={(index === props.selectedTool)}
                  />
          })}
      </div>
    </div>
  );
}

export default Toolbar;
