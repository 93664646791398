import React from 'react';
import PairsCard from './PairsCard';
import './App.css';

function Pairs(props) {
  return (
    <div className="Pairs">
      <div className="Pairs-column">
        {props.data.pairs1.map((item, index) => {
          return <PairsCard 
                  item={item}
                  index={index}
                  data={props.data}
                  lesson={props.lesson}
                  selectPair={props.selectPair}
                  activity={props.activity}
                  topic={props.topic}
                  column={0}
                  state={props.pairs[0][index]}
                  selected={props.pairSelections[0] === index}
                />
        })}
      </div>
      <div className="Pairs-column">
        {props.data.pairs2.map((item, index) => {
          return <PairsCard 
                  item={item}
                  index={index}
                  data={props.data}
                  lesson={props.lesson}
                  selectPair={props.selectPair}
                  activity={props.activity}
                  topic={props.topic}
                  column={1}
                  state={props.pairs[1][index]}
                  selected={props.pairSelections[1] === index}
                />
        })}
      </div>
    </div>
  );
}

export default Pairs;
