import CourseLevelImageBlock from './CourseLevelImageBlock';
import './App.css';

function CourseLevelImage(props) {
  return (
    <div className="CourseLevelImage">
      <div className="CourseLevelImageColumn">
        <CourseLevelImageBlock 
          column={1}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
      </div>
      <div className="CourseLevelImageColumn">
        <CourseLevelImageBlock 
          column={2}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
        <CourseLevelImageBlock 
          column={2}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
      </div>
      <div className="CourseLevelImageColumn">
        <CourseLevelImageBlock 
          column={3}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
        <CourseLevelImageBlock 
          column={3}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
        <CourseLevelImageBlock 
          column={3}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
      </div>
      <div className="CourseLevelImageColumn">
        <CourseLevelImageBlock 
          column={4}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
        <CourseLevelImageBlock 
          column={4}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
        <CourseLevelImageBlock 
          column={4}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
        <CourseLevelImageBlock 
          column={4}
          levelNumber={props.levelNumber}
          levelColors={props.levelColors}
        />
      </div>
    </div>
  );
}

export default CourseLevelImage;
