import React from 'react';
import slash from './images/slash.svg';
import './App.css';

function FillColor(props) {
  function updateColor() {
    props.updateFill(props.color)
  }
  let borderColor = (props.color === "rgba(0,0,0,0)") ? "no-color-icon" : (props.color === "#fff") ? "white-icon" : null;
  return (
    <div className={(props.color === props.fill) ? "selected" : null}>
      <div className={"Color " + borderColor} style={{background: props.color}}onClick={updateColor}>
        {props.color === "rgba(0,0,0,0)" ? <img src={slash} alt="No color" /> : null}
      </div>
    </div>
  );
}

export default FillColor;
