// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBviETWKDxrT0J-r3-bZ199dFDrOfB5fqU",
  authDomain: "skills-8cf76.firebaseapp.com",
  projectId: "skills-8cf76",
  storageBucket: "skills-8cf76.appspot.com",
  messagingSenderId: "842154058365",
  appId: "1:842154058365:web:9ccf689fcf87cbd0fb8714",
  measurementId: "G-4GMS4TJVK1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export const addLearner = (email, progress) => {
  return setDoc(doc(db, "learners", email), {
          email: email,
          forms: {
            l1: progress[0],
            l2: progress[1],
            l3: progress[2],
            l4: progress[3]
          }
      });
};

export const getFormsCourseProgress = async (user) => {
  const docRef = doc(db, "learners", user.email);
  const docSnap = await getDoc(docRef);
  // Check if a db entry exists for this user
  if (docSnap.exists()) {
    let data = docSnap.data().forms
    let formsArray = [data.l1, data.l2, data.l3, data.l4]
    return formsArray
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document");
  }
}

export const updateFormsCourseProgress = async (currentUser, forms) => {

  const learnerRef = doc(db, "learners", currentUser);

  await updateDoc(learnerRef, {
      "forms": forms
  });
}
