import React, { useState } from 'react';
import '../App.css';
import { useNavigate } from "react-router-dom";
import errorWarning from '../images/errorWarning.svg'
import view from '../images/view.svg'
import hide from '../images/hide.svg'

export default function AuthField({ id, title, handleChange, includeReset, tabIndex, errorMsg }) {

  const [showPW, setShowPW] = useState(false);
  const errorStyle = errorMsg ? "AuthField-error" : null;  
  const errorText = errorMsg ? "error-text" : null;
  let navigate = useNavigate();

  function updateValue(event) {
    handleChange(event.target.value)
  }  

  function togglePW() {
    if (showPW) {
      setShowPW(false);
    }
    else {
      setShowPW(true);
    }
  }

  function goToReset() {
    navigate('/reset');
  }

  return (
    <div className="AuthField">
        <label className={"AuthField-container " + errorText}>
            
            {includeReset 
              ? <div className="AuthField-reset-link">
                  <span>{title}</span>
                  <a className="Auth-link" href="/reset" tabIndex={2} onClick={goToReset}>Forgot password?</a>
                </div>
              : title}
            
            <div className="AuthField-input-container">
              <input 
                  id={id}
                  name={id}
                  tabIndex={tabIndex}
                  className={"AuthField-input " + errorStyle}
                  type={(id === "password") ? (showPW ? "text" : id) : id}
                  onChange={updateValue}
                  autoComplete="on"
              />
              {id === "password" 
                ? <img 
                    className="AuthField-pw-icon" 
                    src={showPW ? hide : view} 
                    alt={showPW ? "Hide password" : "View password"} 
                    onClick={togglePW}
                  /> 
                : null}
            </div>
        </label>

        {errorMsg ? 
          <div className="AuthForm-error">
            <img className="AuthForm-error-icon" src={errorWarning} alt="Error warning icon" />
            {errorMsg}
          </div>
          : null
        }

    </div>
  );
}