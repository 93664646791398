import React from 'react';
import './App.css';

function KeyboardFrameRadio(props) {

  const selection = props.selected ? " Frame-radio-selected" : "";
  const focus = props.focused ? " Frame-radio-focus" : "";

  return (
          <div className="Frame-radio-container">
            <div className={"Frame-radio" + selection + focus}>
              <div className={props.selected ? "Frame-radio-filled" : null}></div>
            </div>
            <p className="Frame-radio-label">{props.label}</p>
          </div>
  );
}

export default KeyboardFrameRadio;
