import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import AuthField from './AuthField'
import logo from '../images/logo.svg'
import '../App.css';

function Reset() {

  const [email, setEmail] = useState('');
  // eslint-disable-next-line
  const [errorMsg, setErrorMsg] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  let navigate = useNavigate();

  function goToSignup() {
    navigate('/signup')
  }

  function goToLogin() {
    navigate('/login')
  }

  function goHome() {
    navigate('/')
  }

  function submitForm() {
    const authentication = getAuth();
      sendPasswordResetEmail(authentication, email)
      .then(function() {  
        setSubmitted(true)
        })
        .catch((error) => {
          console.log(error)
        }) 
  }

  return (
    <div className="Course-content">
      <div className="logo-row">
          <img className="Nav-logo" src={logo} onClick={goHome} alt="Merlin logo" />
      </div>
      <div className="AuthForm">
        <div className="AuthForm-content">
          <div className="ResetForm">
            <h2 className="AuthForm-title">{submitted ? "Check your email" : "Reset your password"}</h2>
            <p className="ResetMsg">
              {submitted 
                ? "If there’s an account associated with your email, you’ll receive an email with a password reset link" 
                : "Enter the email address you signed up with and we’ll send you a link to reset your password"
              }
            </p>
          </div>
            <div>
                {submitted 
                  ? null 
                  : <AuthField 
                      id="email"
                      title="Email"
                      value={email}
                      tabIndex={1}
                      errorMsg={errorMsg}
                      handleChange={(value) => setEmail(value)}
                    />}

                <button 
                    className="AuthButton" 
                    onClick={submitted ? goToLogin : submitForm}
                    tabIndex={2}
                >
                  {submitted ? "Back to login" : "Continue"}
                </button>

                {submitted ? null : <p className="Auth-link-message"><a className="Auth-link" href="/login" tabIndex={3} onClick={goToLogin}>Return to login</a></p>}

                <p className="Auth-link-message2">Don't have an account? <a className="Auth-link" href="/signup" tabIndex={4} onClick={goToSignup}>Sign up</a></p>

            </div>

            </div>
        </div>
      </div>
  );
}

export default Reset;
