import React from 'react';
import SelectorOption from './SelectorOption.js'
import vertical from './images/vertical.svg'
import horizontal from './images/horizontal.svg'
import left from './images/left.svg'
import right from './images/right.svg'
import centerH from './images/centerH.svg'
import top from './images/top.svg'
import centerV from './images/centerV.svg'
import bottom from './images/bottom.svg'
import './App.css';

function SelectorTool(props) {
  const directions = [horizontal, vertical]
  const alignment = [left, centerH, right]
  const vAlignment = [top, centerV, bottom]
  const icons = (props.tool === "Direction") ? directions : (props.tool === "V Align") ? vAlignment : alignment;
  const toolCount = (icons.length)
  return (
    <div className="SelectorTool" style={{marginLeft : ((57 * toolCount / 2) * -1 ) + "px"}}>
      {icons.map((icon, index) => {
        return <SelectorOption
                  data={props.data}
                  activity={props.activity}
                  icon={icon}
                  index={index}
                  lastOption={icons.length === (index + 1)}
                  tool={props.tool}
                  toolCount={toolCount}
                  alignment={props.alignment}
                  vAlignment={props.vAlignment}
                  direction={props.direction}
                  updateDirection={props.updateDirection}
                  updateAlignment={props.updateAlignment}
                  updateVAlignment={props.updateVAlignment}
                />
          })}
    </div>
  );
}

export default SelectorTool;
