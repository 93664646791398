import React, { useState } from 'react';
import './App.css';

function Slider(props) {
  const tool = props.tool
  const [showPrompt, setShowPrompt] = useState(true);
  const value = (tool === "Width" ? props.strokeWidth 
                : tool === "Padding" ? props.padding 
                : tool === "V Margin" ? props.vMargin 
                : tool === "H Margin" ? props.hMargin 
                : props.size)
  function updateValue(event) {
    if (showPrompt === true) {
      setShowPrompt(false)
    }
    if (tool === "Width") {
      props.updateStrokeWidth(event.target.value);
    }
    else if (tool === "Padding") {
      props.updatePadding(event.target.value);
    }
    else if (tool === "V Margin") {
      props.updateVmargin(event.target.value);
    }
    else if (tool === "H Margin") {
      props.updateHmargin(event.target.value);
    }
    else {
      props.updateSize(event.target.value);     
    }
  }

  return (
    <div>
      {showPrompt ? <div className="prompt slider-prompt">Use this slider to update the radio button size</div> : null}
      <div className="Slider-container">
        <div className="Slider-value">{value}</div>
        <input 
          type="range" 
          min={props.range[0]} 
          max={props.range[1]} 
          value={value} 
          step={props.range[2]} 
          onChange={updateValue} 
          className="Slider" 
        />
      </div>
    </div>
  );
}

export default Slider;
