import './App.css';

function Feedback(props) {

  return (
    <div className={"Feedback " + props.feedback}>
      <div className="Feedback-content">
        <h4 className={"Feedback-title-" + props.answer}>{props.feedbackMessage[0]}</h4>
        <p className="Feedback-text">{props.feedbackMessage[1]}</p>
        <div className={"Feedback-button " + props.answer} onClick={props.closeFeedback}>
          {props.answer === "incorrect" ? "Try again" : "Continue"}
        </div>
      </div>
    </div>
  );
}

export default Feedback;
