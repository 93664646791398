import React from 'react';
import './App.css';

function SelectorOption(props) {
  const directions = ["row", "column"]
  const alignment = ["flex-start", "center", "flex-end"]
  const radius = props.index === 0 ? "12px 0 0 12px" : props.index === (props.toolCount - 1) ? "0 12px 12px 0" : "0";
  const selected = (props.tool === "Direction" && props.direction === directions[props.index]) || (props.tool === "V Align" && props.vAlignment === alignment[props.index]) || (props.tool === "Align" && props.alignment === alignment[props.index])
  function updateSelection() {
    if (props.tool === "Direction") {
      props.updateDirection(directions[props.index])
    }
    else if (props.tool === "V Align") {
      props.updateVAlignment(alignment[props.index])
    }
    else {
      props.updateAlignment(alignment[props.index])
    }
  }

  return (
    <div className={selected ? "Tool Tool-selected" : "Tool"} style={{borderRadius: radius}}onClick={updateSelection}>
      <img src={props.icon} alt={props.tool} />
    </div>
  );
}

export default SelectorOption;
