import React, { useState } from 'react';
import KeyboardFrame from './KeyboardFrame'
import KeyboardKey from './KeyboardKey';
import './App.css';

function Keyboard(props) {
  const [focus, setFocus] = useState(null);
  const [selection, setSelection] = useState(0);

  function keyPress(key) {
    if (key === 0) {
      if (focus === props.finalLabel) {
        setFocus(null)
      }
      else if (focus !== null) {
        setFocus(focus + 1)
      }
      else {
        setFocus(0)
      }
    }
    else if (key === 1) {
      if (focus === 0) {
        setFocus(null)
      }
      else if (focus > 0) {
        setFocus(focus - 1)
      }
      else {
        setFocus(props.finalLabel)
      }
    }
    else {
      if (focus !== null) {
        setSelection(focus);
        if (focus === props.data.correctAnswer) {
          props.submit(0);
        }
      }
    }

  }
  return (
    <div className="Keyboard">
      <KeyboardFrame 
        data={props.data}
        focus={focus}
        selection={selection}
      />
      <div className="Keyboard-area">
        <div className="Keyboard-content">
          {props.keys.map((keyText, index) => {
            return <KeyboardKey 
                    keyText={keyText}
                    keyWidth={props.data.keySizes[index]}
                    index={index}
                    lesson={props.lesson}
                    keyPress={keyPress}
                    activity={props.activity}
                  />
          })}
        </div>
      </div>
    </div>
  );
}

export default Keyboard;
