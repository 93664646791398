import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import AuthField from './AuthField'
import errorWarning from '../images/errorWarning.svg'
import logo from '../images/logo.svg'
import '../App.css';

function Login(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState([null, null, null]);
  let navigate = useNavigate();

  function goToSignup() {
    navigate('/signup')
  }

  function goHome() {
    navigate('/')
  }

  function submitForm() {
    const authentication = getAuth();
      signInWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
          navigate('/')
          props.storeUserID(email)
        })
        .catch((error) => {
          if(error.code === 'auth/invalid-email'){
            setErrorMsg(['Please use a valid email', null, null]);
          }
          if(error.code === 'auth/user-not-found'){
            setErrorMsg([null, null, 'Incorrect email or password']);
          }
        }) 
  }

  return (
    <div className="Course-content">
      <div className="logo-row">
          <img className="Nav-logo" src={logo} onClick={goHome} alt="Merlin logo" />
      </div>
      <div className="AuthForm">
        <div className="AuthForm-content">
            <h2 className="AuthForm-title">Log in</h2>
            <div>
                <AuthField 
                  id="email"
                  title="Email"
                  value={email}
                  tabIndex={1}
                  errorMsg={errorMsg[0]}
                  handleChange={(value) => setEmail(value)}
                />
                
                <AuthField 
                  id="password"
                  title="Password"
                  includeReset={true}
                  value={password}
                  tabIndex={2}
                  errorMsg={errorMsg[1]} 
                  handleChange={(value) => setPassword(value)}
                />

                {errorMsg[2] ? 
                  <div className="AuthForm-error">
                    <img className="AuthForm-error-icon" src={errorWarning} alt="Error warning icon" />
                    {errorMsg[2]}
                  </div>
                  : null
                }

                <button 
                    className="AuthButton" 
                    onClick={submitForm}
                    tabIndex={3}
                >
                  Continue
                </button>

                <p className="Auth-link-message">Don't have an account? <a className="Auth-link" href="/signup" tabIndex={4} onClick={goToSignup}>Sign up</a></p>

            </div>

            </div>
        </div>
      </div>
  );
}

export default Login;
