import CourseLevelImage from './CourseLevelImage';
import CourseLevelTopicCard from './CourseLevelTopicCard';
import './App.css';
const levelColors = ["level-purple", "level-green", "level-yellow", "level-orange"];

function CourseLevel(props) {
  return (
    <div className="CourseLevel">
      <div className="CourseLevelTitle">
        <CourseLevelImage 
          index={props.index}
          levelNumber={props.levelNumber}
          levelColors={levelColors}
        />
        <div>
          <p className="overline">{"Level " + props.levelNumber}</p>
          <h2>{props.levelName}</h2>
          <div className="CourseLevelProgress">
            <div className={"CourseLevelProgressBar " + levelColors[props.index]}></div>
          </div>
        </div>
      </div>
      <div className="CourseLevelTopics">
        {props.data.map((topic, index) => {
            return <CourseLevelTopicCard
                     key={index.toString()}
                     name={topic.topicName}
                     type={topic.type}
                     lessonTotal={topic.lessons}
                     index={index}
                     level={props.index}
                     status={props.status[index]}
                     imageMargin={topic.imageMargin}
                     imageMaxWidth={topic.imageMaxWidth}
                     openTopic={props.openTopic}
                   />
          })}
      </div>
    </div>
  );
}

export default CourseLevel;
