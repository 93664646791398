import React, { useState, useEffect } from 'react';
import './App.css';
import forms from './images/FormsCourse.jpg'
import formsData from './Data/Forms.json';
import CourseLevel from './CourseLevel';
import Topic from './Topic';
import Nav from './Nav';

function Course(props) {
  const [topic, setTopic] = useState(null);
  const [showNav, setShowNav] = useState(true);
  // eslint-disable-next-line
  const topicData = topic ? Object.values(formsData)[topic[0]][topic[1]] : null;
  
  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth Token')

    if (authToken) {
      props.logIn();
    }
    // eslint-disable-next-line
  }, [])

  function openTopic(level, topic) {
    if (props.formsStatus[level][topic] !== 'lock') { 
      setTopic([level, topic])
    }
  }

  function closeTopic() {
    setTopic(null)
  }

  function closeNav() {
    setShowNav(false);
  }

  function restoreNav() {
    setShowNav(true);
  }

  return (
    <>
      {showNav ? <Nav loggedIn={props.loggedIn} /> : null}
      <div className="Course">
        <div className="Course-content">
          {topic ? 
            <Topic 
              level={topic[0]}
              topic={topic[1]}
              name={topicData.topicName}
              status={props.formsStatus[topic[0]][topic[1]]}
              type={topicData.type}
              description={topicData.description}
              lessonTotal={topicData.lessons}
              closeTopic={closeTopic}
              closeNav={closeNav}
              restoreNav={restoreNav}
              finishLesson={props.finishLesson}
            /> :
            <>
              <div className="Course-info">
                <div className="Course-info-name">
                  <p className="Course-number">Course 1</p>
                  <h1 className="CourseHeading">Form Design</h1>
                </div>
              </div>
              <img className="Course-image" src={forms} alt="Input components" />
              <div>
                {Object.keys(formsData).map((level, index) => {
                  return <CourseLevel
                          key={index.toString()}
                          levelName={level}
                          levelNumber={index + 1}
                          index={index}
                          status={props.formsStatus[index]}
                          data={Object.values(formsData)[index]}
                          openTopic={openTopic}
                        />
                })}
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
}

export default Course;
