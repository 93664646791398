import React from 'react';
import './App.css';

function Tool(props) {

  function showTool() {
    props.showTool(props.name, props.index);
    if (props.name === "Submit") {
      props.submit();
    }
  }
  
  return (
    <>
      {(props.fill === "rgba(0,0,0,0)") && (props.name === "Padding") ? 
        null :
        <div className={props.selected ? "Tool Tool-selected" : "Tool"} onClick={showTool}>
          <img src={props.icon} alt={props.name} />
          <p className="Tool-label">{props.name}</p>
        </div>
      }
    </>
  );
}

export default Tool;