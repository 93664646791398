import React, { useState } from 'react';
import Fill from './Fill'
import Stroke from './Stroke'
import Slider from './Slider'
import Frame from './Frame'
import Toolbar from './Toolbar'
import SelectorTool from './SelectorTool'

import './App.css';

function Editor(props) {
  const content = props.data;
  const [tool, setTool] = useState(null);
  const [selectedTool, setSelectedTool] = useState(null);
  const [size, setSize] = useState(10);
  const [fill, setFill] = useState(content.fill);
  const [stroke, setStroke] = useState(content.stroke);
  const [strokeWidth, setStrokeWidth] = useState(content.strokeWidth);
  const [padding, setPadding] = useState(content.padding);
  const [vMargin, setVmargin] = useState(content.marginV);
  const [hMargin, setHmargin] = useState(content.marginH);
  const [showPrompt, setShowPrompt] = useState(true);
  const [selectionBox, setSelectionBox] = useState(props.data.selectionBox);
  const [direction, setDirection] = useState(content.direction ? content.direction : null)
  const [hAlign, setHAlign] = useState(content.hAlign ? content.hAlign : null)
  const [vAlign, setVAlign] = useState(content.vAlign ? content.vAlign : null)

  function showTool(currentTool, index) {
    if (showPrompt === true) {
      setShowPrompt(false);
    }
    setTool(currentTool);
    if (index < props.data.tools.length - 1) {
      setSelectedTool(index);
    }
    else {
      setSelectedTool(null);
    }
  }

  function updateFill(newFill) {
    setFill(newFill);
  }

  function updateDirection(newDirection) {
    setDirection(newDirection);
  }

  function updateAlignment(newAlign) {
    setHAlign(newAlign)
  }

  function updateVAlignment(newAlign) {
    setVAlign(newAlign)
  }

  function updateStroke(newStroke) {
    setStroke(newStroke);
  }

  function updateSize(newSize) {
    setSize(newSize);
  }

  function updatePadding(newPadding) {
    setPadding(newPadding);
  }

  function updateVmargin(newVmargin) {
    setVmargin(parseInt(newVmargin));
  }

  function updateHmargin(newHmargin) {
    setHmargin(parseInt(newHmargin));
  }

  function updateStrokeWidth(newStrokeWidth) {
    setStrokeWidth(newStrokeWidth);
  }

  function submit() {
    setSelectionBox(false);
    if (props.data.id === "radio1-align2" || props.data.id === "radio1-align4") {
      if (direction === "row") {
        props.submit(1);
      }
      else if (hAlign === "flex-start") {
        props.submit(0)
      }
      else {
        props.submit(2)
      }
    }
    else if (props.data.id === "radio2-selections") {
      if (vMargin < 4) {
        props.submit(1)
      }
      else if (hMargin < 4) {
        props.submit(2)      
      }
      else if (vMargin < hMargin) {
        props.submit(3)      
      }
      else {
        props.submit(0)
      }
    }    
    else if (props.data.id === "radio2-layout2") {
      if (vMargin < 10) {
        props.submit(1)
      }
      else if (hMargin < 5) {
        props.submit(2)      
      }
      else if (vMargin < hMargin) {
        props.submit(3)      
      }
      else if (vAlign !== "flex-start") {
        props.submit(4)
      }
      else {
        props.submit(0)
      }
    }    
    else if (props.data.id === "radio1-states2") {
      if (fill !== "#AE46CA") {
        props.submit(1)
      }
      // eslint-disable-next-line
      else if (padding != "2") {
        props.submit(2)      
      }
      else if (stroke !== "#AE46CA") {
        props.submit(3)      
      }
      // eslint-disable-next-line
      else if (strokeWidth != 2) {
        props.submit(4)      
      }
      else {
        props.submit(0)
      }
    }
    else {
      props.submit(0)
    }
  }

  return (
    <div className="">
      <Frame 
        element={props.data.element}
        size={props.size}
        fill={fill}
        padding={padding}
        hMargin={hMargin}
        vMargin={vMargin}
        stroke={stroke}
        strokeWidth={strokeWidth}
        selectionBox={selectionBox}
        direction={direction}
        hAlign={hAlign}
        vAlign={vAlign}
        data={props.data}
      />
      {tool === 'Fill' ? 
        <Fill 
          data={props.data}
          updateFill={updateFill}
          activity={props.activity}
          fill={fill} />
       : tool === 'Stroke' ? 
        <Stroke 
          data={props.data}
          updateStroke={updateStroke}
          activity={props.activity}
          stroke={stroke} />
       : (tool === 'Direction' || tool === 'Align' || tool === "V Align") ? 
        <SelectorTool 
          data={props.data}
          updateDirection={updateDirection}
          updateAlignment={updateAlignment}
          updateVAlignment={updateVAlignment}
          activity={props.activity}
          direction={direction}
          tool={tool}
          alignment={hAlign}
          vAlignment={vAlign} />
       : (tool === 'Width' || tool === 'Padding' || tool === 'V Margin' || tool === 'H Margin') ? 
        <Slider 
          range={props.data.sliderRange}
          tool={tool}  
          size={size}
          padding={padding}
          vMargin={vMargin}
          hMargin={hMargin}
          strokeWidth={strokeWidth}
          updateSize={updateSize}
          updatePadding={updatePadding}
          updateVmargin={updateVmargin}
          updateHmargin={updateHmargin}
          updateStrokeWidth={updateStrokeWidth} />
       : null}
      {showPrompt ? <div className="prompt">Use these tools to create your design</div> : null}
      <Toolbar 
        data={props.data}
        submit={submit}
        showTool={showTool}
        updateDirection={updateDirection}
        updateAlignment={updateAlignment}
        fill={fill}
        selectedTool={selectedTool}
      />

    </div>
  );
}

export default Editor;
