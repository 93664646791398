import React from 'react';
import './App.css';


function KeyboardKey(props) {
  function keyPress() {
    props.keyPress(props.index);
  }

  return (
    <div className="KeyboardKey" onClick={keyPress} style={{width: props.keyWidth}}>
      <p className="KeyboardKey-label">{props.keyText}</p>
    </div>
  );
}

export default KeyboardKey;
