import React from 'react';
import slash from './images/slash.svg';
import './App.css';

function StrokeColor(props) {
  const noColor = (props.index === 0 && props.strokeColor === "#C4505E")
  const stroke = noColor ? "rgba(0,0,0,0)" : props.strokeColor;

  function updateStroke() {
    props.updateStroke(stroke)
  }

  return (
    <div className={props.stroke === props.strokeColor ? "selected" : null}>
      <div className={"StrokeColor"} style={{borderColor: props.strokeColor}} onClick={updateStroke}>
        {noColor ? <img src={slash} alt="No color" /> : null}
      </div>
    </div>
  );
}

export default StrokeColor;