import React from 'react';
import close from './images/close.svg'
import './App.css';

function ActivityProgressBar(props) {
  
  const barLength = {
    width: 100 * (props.activity) / props.total + '%',
    minWidth: '12px'
  };

  return (
    <div className="ActivityProgressBar">
      <div className="ActivityProgressBar-bg">
        <div className="ActivityProgressBar-bar" style={barLength}></div>
      </div>
      <img 
        className="ActivityClose" 
        onClick={props.closeLesson}
        src={close} 
        alt="Exit lesson" 
      />
    </div>
  );
}

export default ActivityProgressBar;