import React, { useState, useEffect } from 'react';
import './App.css';
import Lesson from './Lesson';
import radioButtons from './Data/RadioButtons.json'
import checkboxes from './Data/Checkboxes.json'
import TopicOverview from './TopicOverview';

const dataSets = [radioButtons, checkboxes]

function Topic(props) {
  const [lesson, setLesson] = useState(null);
  const [activity, setActivity] = useState(0);
  const [lessonStatus, setLessonStatus] = useState(Array(Object.keys(dataSets[props.topic]).length).fill('lock'));

  // Get the lesson content for the current topic
  const data = Object.values(dataSets[props.topic])

  useEffect(() => {
    const currentStatus = lessonStatus.slice();

    // If the Topic's status is 'done', set all lessons to 'done'
    if (props.status === 'done') {
      for (let i = 0; i < currentStatus.length; i++) {
        currentStatus[i] = 'done'
      }
    }
    // Otherwise, set the lesson statuses according to which are done, open, or locked
    else {
      for (let i = 0; i < currentStatus.length; i++) {
        if (i < props.status) {
          currentStatus[i] = 'done'
        }
        else if (i === props.status) {
          currentStatus[i] = 'open'
        }
        else {
          currentStatus[i] = 'lock'
        }
      }
    }
    setLessonStatus(currentStatus);
  });

  function openLesson(lesson) {
    if (lessonStatus[lesson] !== 'lock') {
      setLesson(lesson)
    }
    props.closeNav()
  }

  function closeLesson() {
    setLesson(null);
    setActivity(0);
    props.restoreNav()
  }

  function nextActivity() {
    if (activity === (Object.keys(data[lesson]).length - 1)) {
      setActivity('success');
      props.finishLesson(props.level, props.topic, lesson);
    }
    else {
      setActivity(activity + 1);
      window.scrollTo(0, 0);
    }
  }

  function nextLesson() {
    setActivity(0);
    setLesson(lesson + 1);
  }

  return (
    <div>
      {lesson === null ? 
        <TopicOverview 
          level={props.level}
          topic={props.topic}
          name={props.name}
          type={props.type}
          data={dataSets[props.topic]}
          status={lessonStatus}
          description={props.description}
          closeTopic={props.closeTopic}
          openLesson={openLesson} /> :
        <Lesson 
          level={props.level}
          topic={props.topic}
          lesson={lesson} 
          lessonTotal={props.lessonTotal}
          activity={activity}
          data={data[lesson][activity]} 
          nextActivity={nextActivity}
          closeLesson={closeLesson}
          nextLesson={nextLesson}
        /> 
      }
    </div>
  );
}

export default Topic;
