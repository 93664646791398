import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { addLearner } from '../Firebase'
import { useNavigate } from "react-router-dom";
import AuthField from './AuthField'
import logo from '../images/logo.svg'
import '../App.css';

function Signup(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState([null, null]);
  let navigate = useNavigate();

  function goToLogin() {
    navigate('/login')
  }

  function goHome() {
    navigate('/')
  }

  function submitForm() {
    const authentication = getAuth();
    createUserWithEmailAndPassword(authentication, email, password)
      .then((response) => {
        //Once the user creation has happened successfully, add the currentUser into Firestore
        addLearner(email, props.formsProgress);
        // After signup, take them to the Course page - change this to the Courses page
        sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
        navigate('/course')
      })
      .catch((error) => {
        if(error.code === 'auth/invalid-email'){
          setErrorMsg(['Please use a valid email', null]);
        }
        if(error.code === 'auth/email-already-in-use'){
          setErrorMsg(['Email already in use', null]);
        }
        if(error.code === 'auth/weak-password'){
          setErrorMsg([null, 'Make sure you have least 6 characters']);
        }
      })
  }

  return (
    <div className="Course-content">
      <div className="logo-row">
          <img className="Nav-logo" src={logo}  onClick={goHome} alt="Merlin logo" />
      </div>
      <div className="AuthForm">
        <div className="AuthForm-content">
            <h2 className="AuthForm-title">Create a free account</h2>
            <div>
                <AuthField 
                  id="email"
                  title="Email"
                  value={email}
                  tabIndex={1}
                  errorMsg={errorMsg[0]}
                  handleChange={(value) => setEmail(value)}
                />
                
                <AuthField 
                  id="password"
                  title="Password"
                  value={password}
                  tabIndex={2}
                  errorMsg={errorMsg[1]} 
                  handleChange={(value) => setPassword(value)}
                />

                <button className="AuthButton" tabIndex={3} onClick={submitForm}>
                  Create account
                </button>

                <p className="Auth-link-message">Have an account? <a className="Auth-link" href="/login" tabIndex={4} onClick={goToLogin}>Log in</a></p>

            </div>

            </div>
        </div>
      </div>
  );
}

export default Signup;
