import React from 'react';
import { useNavigate } from "react-router-dom";
import './App.css';
import logo from './images/logo.svg'
import account from './images/account.svg'

function Nav(props) {
  let navigate = useNavigate();

  function goToSignup() {
    navigate('/signup')
  }

  function goToLogin() {
    navigate('/login')
  }

  function goHome() {
    navigate('/')
  }

  const handleLogout = () => {
    sessionStorage.removeItem('Auth Token');
    navigate('/login')
}

  return (
    <div className="Nav">
      <img className="Nav-logo" src={logo} onClick={goHome} alt="Merlin logo" />
      {props.loggedIn 
        ? <div className="Nav-logout" onClick={handleLogout}>
           <img className="Nav-logout-icon" src={account} alt="user icon" />
           Log out
          </div> 
        : <div className="Nav-actions">
            <p onClick={goToLogin}>Log in</p>
            <div className="Continue-button btn-small" onClick={goToSignup}>Sign up free</div>
          </div>
        }
    </div>
  );
}


export default Nav;