import React, { useState } from 'react';
import './App.css';
import lock from './images/lockedTopic.svg'
import start from './images/startTopic.svg'
import play from './images/play.svg'
import CourseLevelTopicCardImage from './CourseLevelTopicCardImage';

function CourseLevelTopicCard(props) {
  const [completion, setCompletion] = useState(.5);
  const unlocked = (props.status !== "lock")
  function openTopic() {
    props.openTopic(props.level, props.index);
  }

  const progress = (props.status === 0) ? 5 : (props.status === 'done') ? 100 : (props.status * 100 / props.lessonTotal)
  const offset = (151 - (151 * progress) / 100);

  return (
    <div className="CourseLevelTopicCardContainer" onClick={openTopic}>
      <CourseLevelTopicCardImage 
        topicIndex={props.index}
        unlocked={unlocked}
        imageMargin={props.imageMargin}
        imageMaxWidth={props.imageMaxWidth}
        level={props.level}
        name={props.name}
      />
      <div className="CourseLevelTopicCard">
        <div className="CourseLevelTopicCardContent">
          <div>
            <p className="overline">{props.type}</p>
            <h3 className="CourseLevelTopicCardTitle">{props.name}</h3>
          </div>
          {unlocked 
            ? <div className="CourseLevelTopicStatus">
                <svg  className="CourseLevelTopicStatusRing">
                  <circle className="StatusRing-bg" cx="24" cy="24" r="24"></circle>
                  <circle className="StatusRing" cx="24" cy="24" r="24" style={{strokeDashoffset: offset}}></circle>
                </svg>
                <div className="CourseLevelTopicStatusButton">
                  <img className="CourseLevelTopicStatusButton-icon" src={play} alt="open topic" />
                </div>
              </div>
            : <img className="CourseLevelTopicStatus-lock" src={lock} alt="Locked topic" />
            }

        </div>
      </div>
    </div>
  );
}

export default CourseLevelTopicCard;
