import React, { useState } from 'react';
import './App.css';

function DemoRadio(props) {
  const [demoRadioSelection, setDemoRadioSelection] = useState([true, false]);

  function selectDemoRadioOne() {
    setDemoRadioSelection([true, false])
  }

  function selectDemoRadioTwo() {
    setDemoRadioSelection([false, true])
  }

  return (
    <form className="DemoForm">
      <input className="demoRadio" type="radio" id="first" name="demo" value="Hotdog" checked={demoRadioSelection[0]} onChange={selectDemoRadioOne} />
      <label for="first">Option 1</label><br />
      <input className="demoRadio" type="radio" id="second" name="demo2" value="Not hotdog" checked={demoRadioSelection[1]} onChange={selectDemoRadioTwo} />
      <label for="second">Option 2</label><br />
    </form>
  );
}


export default DemoRadio;