import React from 'react';
import ActivityCardTitle from './ActivityCardTitle';
import DemoRadio from './DemoRadio';
import CardExample from './CardExample';
import correct from './images/correct.svg'
import incorrect from './images/incorrect.svg'
import forms1a2a from './images/forms1a2a.svg'
import forms1a3a from './images/forms1a3a.svg'
import forms1a5a from './images/forms1a5a.png'
import forms1a5b from './images/forms1a5b.png'
import forms1a7a from './images/forms1a7a.svg'
import forms1a9a from './images/forms1a9a.svg'
import forms1b1a from './images/forms1b1a.svg'
import forms1b2a from './images/forms1b2a.svg'
import forms1b3a from './images/forms1b3a.svg'
import forms1b5a from './images/forms1b5a.svg'
import forms1b7a from './images/forms1b7a.svg'
import forms1c1a from './images/forms1c1a.png'
import forms1c5a from './images/forms1c5a.svg'
import forms1c7a from './images/forms1c7a.png'
import forms1c11a from './images/forms1c11a.png'
import forms1c13a from './images/forms1c13a.png'
import forms2a3a from './images/forms2a3a.svg'
import forms2a7a from './images/forms2a7a.svg'
import forms2a9a from './images/forms2a9a.svg'
import forms2a11a from './images/forms2a11a.svg'
import placeholder from './images/placeholderImg.svg'
import './App.css';

const radio1 = [0, forms1a2a, forms1a3a, forms1a5a, forms1a5b, forms1a7a, forms1a9a, placeholder]
const radio2 = [0, forms1b1a, forms1b2a, forms1b3a, forms1b5a, forms1b7a]
const radio3 = [0, forms1c1a, forms1c5a, forms1c7a, forms1c11a, forms1c13a]
const checkbox1 = [0, forms2a3a, forms2a7a, forms2a9a, forms2a11a]

const radioImages = [radio1, radio2, radio3]
const checkboxImages = [checkbox1]
const images = [radioImages, checkboxImages]

function ActivityCard(props) {

  return (
    <div className="ActivityCard">
      <div className="ActivityCard-content">
        <ActivityCardTitle 
          data={props.data} 
          icon={props.data.cardIcon}
          title={props.data.card}
          span={props.data.cardSpan}
        />

        {props.data.cardDemo ? <DemoRadio /> : null}

        {props.data.cardExample ? <CardExample /> : null}

        {props.data.cardImage ? 
        
          <div className="ActivityCard-image-container">
            {props.data.badges ? <img className="ActivityCard-badge" src={correct} alt="checkmark" /> : null}
            <img 
              className="ActivityCard-image" 
              src={images[props.topic][props.lesson][props.data.cardImage]} 
              alt={props.data.cardImageAlt} 
            />
          </div>
           : null}

        {props.data.card2 || props.data.cardSpan2 ?
          <ActivityCardTitle 
            data={props.data} 
            title={props.data.card2}
            span={props.data.cardSpan2}
            space={true}
          />
          : null}

        {props.data.cardImage2 ? 
          <div className="ActivityCard-image-container">
            {props.data.badges ? <img className="ActivityCard-badge" src={incorrect} alt="X icon" /> : null}
            <img 
              className="ActivityCard-image" 
              src={images[props.topic][props.lesson][props.data.cardImage2]} 
              alt={props.data.cardImageAlt2} 
            />
          </div>
           : null}

      </div>
    </div>
  );
}


export default ActivityCard;