import React from 'react';
import './App.css';

function Button(props) {

  return (
    <div 
      className={props.relative ? "Continue-button" : "Continue-button Continue-button-fixed"} 
      onClick={props.continue}
    >
      {props.text}
    </div>
  );
}


export default Button;