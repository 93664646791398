import React from 'react';
import './App.css';
import LessonSuccess from './LessonSuccess';
import radioButtons from './Data/RadioButtons.json'
import checkboxes from './Data/Checkboxes.json'
import Activity from './Activity';

const data = [radioButtons, checkboxes]

function Lesson(props) {
  // eslint-disable-next-line
  const activityTotal = (Object.values(data[props.topic]))[props.lesson].length;
  return (
    <div>
      {props.activity === "success" ? 
        <LessonSuccess 
          topic={props.topic}
          activity={props.activity}
          lesson={props.lesson}
          lessonTotal={props.lessonTotal}
          nextLesson={props.nextLesson}
          closeLesson={props.closeLesson} /> : 
        <Activity 
          topic={props.topic}
          lesson={props.lesson} 
          activity={props.activity}
          total={activityTotal}
          data={props.data} 
          nextActivity={props.nextActivity}
          closeLesson={props.closeLesson}
        /> 
      }
    </div>
  );
}

export default Lesson;
