import React from 'react';
import './App.css';
import forms1c10a from './images/forms1c10a.svg'
import forms2a5a from './images/forms2a5a.svg'
import forms2a6a from './images/forms2a6a.svg'

const radio3 = [0, forms1c10a]
const checkbox1 = [0, forms2a5a, forms2a6a]
const radioImages = [null, null, radio3]
const checkboxImages = [checkbox1]
const images = [radioImages, checkboxImages]

function TitleImage(props) {

  return (
    <img 
      className="TitleImage" 
      src={images[props.topic][props.lesson][props.data.titleImage]} 
      alt={props.data.titleImageAlt} 
    />
  );
}


export default TitleImage;