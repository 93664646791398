import React from 'react';
import forms1c9a from './images/forms1c9a.svg'
import forms1c9b from './images/forms1c9b.svg'
import forms1c9c from './images/forms1c9c.svg'
import forms1c9d from './images/forms1c9d.svg'
import forms1c9e from './images/forms1c9e.svg'
import forms1c12a from './images/forms1c12a.svg'
import forms1c12b from './images/forms1c12b.svg'
import forms1c12c from './images/forms1c12c.svg'
import forms1c12d from './images/forms1c12d.svg'
import forms1c12e from './images/forms1c12e.svg'
import forms2a1a from './images/forms2a1a.png'
import forms2a1b from './images/forms2a1b.png'
import forms2a1c from './images/forms2a1c.png'
import forms2a1d from './images/forms2a1d.svg'
import forms2a1e from './images/forms2a1e.svg'
import forms2a1f from './images/forms2a1f.svg'
import forms2a12a from './images/forms2a12a.svg'
import forms2a12b from './images/forms2a12b.svg'
import forms2a12c from './images/forms2a12c.svg'
import forms2a12d from './images/forms2a12d.svg'
import forms2a12e from './images/forms2a12e.svg'
import './App.css';


const radioImages = [null, [forms1c9a, forms1c9b, forms1c9c, forms1c9d, forms1c9e], [forms1c12a, forms1c12b, forms1c12c, forms1c12d, forms1c12e]]
const checkboxImages = [null, [forms2a1a, forms2a1b, forms2a1c], [forms2a1d, forms2a1e, forms2a1f], [forms2a12a, forms2a12b, forms2a12c, forms2a12d, forms2a12e]]
const images = [radioImages, checkboxImages]


function PairsCard(props) {
  
  function selectPair() {
    props.selectPair(props.column, props.index);
  }
  const isImage = Number.isInteger(props.item);
  const backgroundColor = isImage ? "#ffffff" : null;
  const opacity = (isImage && props.selected) ? .85 : 1;
  const cardClass = props.selected ? "PairsCard-selected" : props.state === "incorrect" ? "PairsCard-incorrect" : props.state === true ? "PairsCard-correct" : null;
  
  return (
    <div className={"PairsCard " + cardClass} style={{background: backgroundColor, opacity: opacity}} onClick={selectPair}>
      {isImage ?
        <img className="PairsCard-image" src={images[props.topic][props.item][props.index]} alt="test" />
        : <p className="PairsCard-text">{props.item}</p>
      }
    </div>
  );
}

export default PairsCard;