import React from 'react';
import './App.css';
import TopicOverviewLesson from './TopicOverviewLesson';
import radio from './images/radio-lg.png'
import checkbox from './images/checkboxes-lg.png'
import back from './images/back.svg'

// Custom adjustments for each image
const imgStyles = [["50%", "-50px auto -46px"], ["55%", "-30px auto -36px"]]
const images = [radio, checkbox]

function TopicOverview(props) {
  const totalLessons = Object.keys(props.data).length
  const overallWidth = totalLessons === 2 ? "50%" : "90%";
  const lineMaxWidth = totalLessons === 2 ? "108px" : "255px"
  const lineWidth = props.status[2] !== "lock" ? "100%" : props.status[1] !== "lock" ? "50%" : 0;

  return (
    <div className="">
      <img className="absolute t-12" onClick={props.closeTopic} src={back} alt="back" />
      <div className="TopicOverview">
        <img className="TopicOverview-image" src={images[props.topic]} alt={props.name} style={{width: imgStyles[props.topic][0], margin: imgStyles[props.topic][1]}}/>
        <div className="TopicOverview-content">
          <p className="TopicOverview-type">{props.type}</p>
          <h1 className="TopicOverview-heading">{props.name}</h1>
          <p className="TopicOverview-description">{props.description}</p>
            <div className="TopicOverview-lessons" style={{width: overallWidth}}>
              <div className="TopicOverview-lessons-line" style={{maxWidth: lineMaxWidth}}>
                <div className="TopicOverview-lessons-line-fill" style={{width: lineWidth}}></div>
              </div>
              {Object.keys(props.data).map((lesson, index) => {
              return <TopicOverviewLesson 
                        key={index.toString()}
                        lesson={lesson} 
                        index={index}
                        openLesson={props.openLesson}
                        status={props.status[index]}
                    />
              })}
            </div>  
          </div>
      </div>
    </div>
  );
}

export default TopicOverview;
