import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { app, updateFormsCourseProgress, getFormsCourseProgress} from './Firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Course from './Course'
import Login from './Authentication/Login'
import Signup from './Authentication/Signup'
import Reset from './Authentication/Reset'
import './App.css';
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";


function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [forms, setForms] = useState([[0, 'lock', 'lock', 'lock', 'lock', 'lock', 'lock', 'lock'], ['lock', 'lock', 'lock', 'lock', 'lock', 'lock'], ['lock', 'lock', 'lock', 'lock', 'lock', 'lock', 'lock'], ['lock', 'lock', 'lock', 'lock', 'lock', 'lock', 'lock']])
  const [currentUser, setCurrentUser] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth Token')
    if (authToken) {
      setLoggedIn(true);
      navigate('/')
    }

    // Store the current user email to state
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user.email)
      }
    })

    storeProgressToState()

    // eslint-disable-next-line
  }, [])

  function storeProgressToState() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        let data = await getFormsCourseProgress(user);
        setForms(data)
      }
    })
  }

  function logIn() {
    setLoggedIn(true);
  }

  function storeUserID(email) {
    setCurrentUser(email);
  }

  function finishLesson(level, topic, lesson) {
    const formsProgress = forms.slice();
    const current = formsProgress[level][topic]; 
    console.log(lesson, topic, level, current)
    // Only make changes if the newly completed lesson was the "open" lesson (only one lesson is open, all others are "done" or "locked")
    if (lesson === current) {
      // If the topic had 0 or 1 lessons already completed, add 1 to that total
      if (current < 2) {
        formsProgress[level][topic] = (current + 1);
      }
      // Otherwise mark this topic as done and unlock the next topic
      else {
        formsProgress[level][topic] = 'done';
        formsProgress[level][(topic + 1)] = 0;
      }

      // Save their progress to state
      storeProgressToState();

      // If the user is logged in, save their progress to the DB
      if (currentUser) {
        const formsObject = {l1: formsProgress[0], l2: formsProgress[1], l3: formsProgress[2], l4: formsProgress[3]}
        updateFormsCourseProgress(currentUser, formsObject)
      }
      else {
        setForms(formsProgress)
      }
    }
  }

  return (
      <div className="App">
        <Routes>
          <Route 
            path='/login' 
            element={<Login storeUserID={storeUserID} />} 
          />
          <Route 
            path='/signup' 
            element={<Signup formsProgress={forms} />} 
          />
          <Route
            path='/reset'
            element={<Reset />}
          />
          <Route
            path='/course'
            element={<Course loggedIn={loggedIn} formsStatus={forms} finishLesson={finishLesson} logIn={logIn} />}
          />
          <Route
            path='/'
            element={<Course loggedIn={loggedIn} formsStatus={forms} finishLesson={finishLesson} logIn={logIn} />}
          />
        </Routes>
      </div>
  );
}

export default App;
