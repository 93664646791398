import React from 'react';
import StrokeColor from './StrokeColor.js'
import './App.css';

function Stroke(props) {

  return (
    <div className="Fill">
      <div className="Fill-row">
        {props.data.strokes1.map((strokeColor, index) => {
          return <StrokeColor
                    strokeColor={strokeColor}
                    updateStroke={props.updateStroke}
                    stroke={props.stroke}
                    index={index}
                  />
            })}
      </div>
      <div className="Fill-row">
        {props.data.strokes2.map((strokeColor, index) => {
          return <StrokeColor
                    strokeColor={strokeColor}
                    updateStroke={props.updateStroke}
                    stroke={props.stroke}
                    index={index}
                  />
            })}
          </div>
    </div>
  );
}

export default Stroke;
