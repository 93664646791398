import bolt from './images/bolt.svg';
import bulb from './images/bulb.svg';
import './App.css';

function ActivityCardTitle(props) {
  const icon = (props.data.cardIcon === "bolt" ? bolt : bulb)
  return (
    <div className={"ActivityCardTitle " + (props.space ? "mt-30" : null)} >
      {props.icon ? 
        <img className="ActivityCardTitle-icon" src={icon} alt={props.icon} /> 
        : null}
      {<p className="ActivityCardTitle-text">{props.title} {props.span ? <span className="ActivityCardTitle-span">{props.span}</span> : null} </p>}
    </div>
  );
}

export default ActivityCardTitle;