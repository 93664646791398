import React from 'react';
import play from './images/play.svg'
import replay from './images/replay.svg'
import './App.css';

function TopicOverviewLesson(props) {
  const unlocked = props.status === "open";
  const done = props.status === "done";
  function openLesson() {
    props.openLesson(props.index);
  }
  return (
    <div className="TopicOverviewLesson" onClick={openLesson}>
      <div className={unlocked ? "TopicOverviewLesson-dot-unlocked" : done ? "TopicOverviewLesson-dot-done" : "TopicOverviewLesson-dot"}>
        {unlocked ? <img className="TopicOverviewLesson-dot-image" src={play} alt="Start lesson" /> : null}
        {done ? <img className="TopicOverviewLesson-dot-image-replay" src={replay} alt="Replay completed lesson" /> : null}
      </div>
      <p className={unlocked ? "TopicOverviewLesson-label-unlocked" : "TopicOverviewLesson-label"}>{props.lesson}</p>
    </div>
  );
}

export default TopicOverviewLesson;
