import React from 'react';
import './App.css';
import forms1a1a from './images/forms1a1a.svg'
import forms1a1b from './images/forms1a1b.svg'
import forms1a1c from './images/forms1a1c.svg'
import forms1a3b from './images/forms1a3b.svg'
import forms1a3c from './images/forms1a3c.svg'
import forms1a4a from './images/forms1a4a.svg'
import forms1a4b from './images/forms1a4b.svg'
import forms1a4c from './images/forms1a4c.svg'
import forms1b1b from './images/forms1b1b.svg'
import forms1b1c from './images/forms1b1c.svg'
import forms1b1d from './images/forms1b1d.svg'
import forms1b6a from './images/forms1b6a.svg'
import forms1b6b from './images/forms1b6b.svg'
import forms1b6c from './images/forms1b6c.svg'
import forms1b7b from './images/forms1b7b.svg'
import forms1b7c from './images/forms1b7c.svg'
import forms1c2a from './images/forms1c2a.jpg'
import forms1c2b from './images/forms1c2b.jpg'
import forms1c2c from './images/forms1c2c.jpg'
import forms1c3a from './images/forms1c3a.jpg'
import forms1c3b from './images/forms1c3b.jpg'
import forms1c3c from './images/forms1c3c.jpg'
import forms1c4a from './images/forms1c4a.jpg'
import forms1c4b from './images/forms1c4b.jpg'
import forms1c4c from './images/forms1c4c.jpg'
import forms1c6a from './images/forms1c6a.jpg'
import forms1c6b from './images/forms1c6b.jpg'

import forms2a2a from './images/forms2a2a.svg'
import forms2a2b from './images/forms2a2b.svg'
import forms2a2c from './images/forms2a2c.svg'
import forms2a2d from './images/forms2a2d.svg'
import forms2a4a from './images/forms2a4a.jpg'
import forms2a4b from './images/forms2a4b.jpg'
import forms2a6a from './images/forms2a6b.svg'
import forms2a6b from './images/forms2a6c.svg'
import forms2a8a from './images/forms2a8a.svg'
import forms2a8b from './images/forms2a8b.svg'
import forms2a8c from './images/forms2a8c.svg'
import forms2a8d from './images/forms2a8d.svg'
import forms2a10a from './images/forms2a10a.svg'
import forms2a10b from './images/forms2a10b.svg'
import forms2a10c from './images/forms2a10c.svg'
import forms2a10d from './images/forms2a10d.svg'
import placeholder from './images/placeholder.svg'

const radio1 = [[forms1a1a, forms1a1b, forms1a1c], [null], [forms1a3b, forms1a3c], [forms1a4a, forms1a4b, forms1a4c]];
const radio2 = [[forms1b1b, forms1b1c, forms1b1d], [null], [null], [null], [null], [forms1b6a, forms1b6b, forms1b6c], [forms1b7b, forms1b7c]]
const radio3 = [[null], [forms1c2a, forms1c2b, forms1c2c], [forms1c3a, forms1c3b, forms1c3c], [forms1c4a, forms1c4b, forms1c4c], [null], [forms1c6a, forms1c6b], [null], [placeholder, placeholder, placeholder, placeholder], [null], [null]]
const checkbox1 = [[null], [forms2a2a, forms2a2b, forms2a2c, forms2a2d], [null], [forms2a4a, forms2a4b], [null], [forms2a6a, forms2a6b], [null], [forms2a8a, forms2a8b, forms2a8c, forms2a8d], [null], [forms2a10a, forms2a10b, forms2a10c, forms2a10d]]
const radioImages = [radio1, radio2, radio3]
const checkboxImages = [checkbox1]
const images = [radioImages, checkboxImages]


function ActivityAnswersCard(props) {
  const currentImage = images[props.topic][props.lesson][props.activity][props.index]
  const gridMargin = props.data.grid ? "16px 16px 0 0" : null;
  const customMargin = props.data.imageMargin ? props.data.imageMargin : null;
  const customRadius = props.data.imageMargin ? "3px" : null;
  function selectAnswer() {
    props.selectAnswer(props.index);
  }

  return (
    <div className={props.selected ? "ActivityAnswersCard ActivityAnswersCard-selected" : "ActivityAnswersCard"} style={{margin: gridMargin}} onClick={selectAnswer}>
      {props.option ? <p className="ActivityAnswersCard-text">{props.option}</p> : null}
     
      {currentImage ?
        <div className={props.option ? "ActivityAnswersCardImage-area" : null}>
          <div className="ActivityAnswersCardImage-card">
            <img className="ActivityAnswersCardImage" src={currentImage} style={{margin: customMargin, borderRadius: customRadius}}alt="test" />
          </div>
        </div> 
      : null}
    </div>
  );
}

export default ActivityAnswersCard;
