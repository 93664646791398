import React from 'react';
import './App.css';

function Frame(props) {
  const size = props.size + "px"
  const box = props.selectionBox;
  const marginH = props.hMargin + "px"
  const marginV = props.vMargin + "px"

  
  return (
    <div className="Frame">
      <div className="Frame-content">
        {props.element === "radio" ?
          <div className="Frame-radio-container"><div className="Frame-radio" style={{width: size, height: size, borderRadius: size}}></div>
              <p className="Frame-radio-label">Label</p>
          </div>
         : props.element === "radioGroup" ?
          <div className="Frame-radio-group" style={{flexDirection: props.direction, alignItems: props.hAlign, justifyContent: props.hAlign}}>
            <div className="Frame-radio-container"  style={{marginTop: 0, marginBottom: marginV, alignItems: props.vAlign}}>
              <div className={box ? "Frame-selected" : null}>
                <div className={box ? "Frame-selected-corner-1" : null}></div>
                <div className={box ? "Frame-selected-corner-2" : null}></div>
                <div className={box ? "Frame-selected-corner-3" : null}></div>
                <div className={box ? "Frame-selected-corner-4" : null}></div>
                <div className="Frame-radio" style={{border: props.strokeWidth + "px solid " + props.stroke, padding: props.padding + "px"}}>
                  <div className="Frame-radio-fill" style={{background: props.fill}}></div>
                </div>
              </div>
              <p className="Frame-radio-label" style={{marginLeft: marginH}}>{props.data.labels[0]}</p>
            </div>
            <div className="Frame-radio-container"  style={{marginTop: 0, marginBottom: marginV, alignItems: props.vAlign}}>
              <div className="Frame-radio" style={{}}></div>
              <p className="Frame-radio-label" style={{marginLeft: marginH}}>{props.data.labels[1]}</p>
            </div>
            {props.data.labels[2] ? 
              <div className="Frame-radio-container" style={{marginTop: 0, marginBottom: marginV, alignItems: props.vAlign}}>
                <div className="Frame-radio" style={{}}></div>
                <p className="Frame-radio-label" style={{marginLeft: marginH}}>{props.data.labels[2]}</p>
              </div>
              : null}
            {props.data.labels[3] ? 
              <div className="Frame-radio-container" style={{marginTop: 0, marginBottom: marginV, alignItems: props.vAlign}}>
                <div className="Frame-radio" style={{}}></div>
                <p className="Frame-radio-label" style={{marginLeft: marginH}}>{props.data.labels[3]}</p>
              </div>
              : null}
          </div>
         : null
        }
      </div>
    </div>
  );
}

export default Frame;
