import React from 'react';
import './App.css';

function CardExample() {
  
  return (
    <div className="CardExample Frame-radio-container">
      <div className="Frame-radio" style={{border: "2px solid #AE46CA", padding: "2px"}}>
        <div className="Frame-radio-fill" style={{background: "#AE46CA"}}></div>
      </div>
      <p className="Frame-radio-label">Selected</p>
    </div>
  );
}

export default CardExample;
