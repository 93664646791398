import React from 'react';
import KeyboardFrameRadio from './KeyboardFrameRadio'
import './App.css';

function KeyboardFrame(props) {

  return (
    <div className="Frame">
      <div className="Frame-content">
        <div className="Frame-radio-group">
            {props.data.labels.map((label, index) => {
                return <KeyboardFrameRadio 
                        label={label}
                        index={index}
                        focused={props.focus === index}
                        selected={props.selection === index}
                      />
            })}
        </div>
      </div>
    </div>
  );
}

export default KeyboardFrame;
