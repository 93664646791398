import React, { useState } from 'react';
import ActivityProgressBar from './ActivityProgressBar';
import ActivityCard from './ActivityCard';
import Frame from './Frame'
import Slider from './Slider'
import Editor from './Editor'
import Pairs from './Pairs'
import ActivityAnswers from './ActivityAnswers';
import TitleImage from './TitleImage'
import Keyboard from './Keyboard'
import Feedback from './Feedback';
import Button from './Button';
import './App.css';

function Activity(props) {
  
  const [answer, setAnswer] = useState(null);
  const [multiSelections, setMultiSelections] = useState([null]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState([null, null]);
  const [size, setSize] = useState(10);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [pairs, setPairs] = useState([[false, false, false, false, false], [false, false, false, false, false]]);
  const [pairSelections, setPairSelections] = useState([null, null]);
  const [correctPairs, setCorrectPairs] = useState(0);

  function selectAnswer(selection) {
    if (props.data.type === "multiSelect") {
      if (multiSelections[0] === null) {
        const selections = Array(props.data.options.length).fill(false);
        selections[selection] = true;
        setMultiSelections(selections);
        setShowContinueButton(true)
      }
      else {
        const selections = multiSelections.slice();
        if (selections[selection] === true) {
          selections[selection] = false
        }
        else {
          selections[selection] = true;
        }
        setMultiSelections(selections);
      }
    }
    else {
      submitAnswer(selection)
    }
  }

  function selectPair(column, selection) {
    const currentPairs = pairs.slice();
    const currentSelections = pairSelections.slice()
    const currentValue = currentPairs[column][selection];
    const oppositeColumn = (column === 0) ? 1 : 0;
    const oppositeSelection = pairSelections[oppositeColumn];
    const correctPartner = props.data.correctPairs[column][selection];
    
    if (currentValue !== true) {
      for (let i = 0; i < pairs[column].length; i++) {
        if (pairs[column][i] === "incorrect") {
          currentPairs[column][i] = false;
        }
        if (pairs[oppositeColumn][i] === "incorrect") {
          currentPairs[oppositeColumn][i] = false;
        }
        setPairs(currentPairs);
      }
      if (oppositeSelection === null) {
        currentSelections[column] = selection;
        setPairSelections(currentSelections);
      }
      else if (oppositeSelection === correctPartner) {
        currentPairs[column][selection] = true;
        currentPairs[oppositeColumn][oppositeSelection] = true;
        setCorrectPairs(correctPairs + 1);
        setPairs(currentPairs);
        setPairSelections([null, null]);
        if (correctPairs === (props.data.correctPairs[0].length - 1)) {
          setAnswer('correct');
          setFeedbackMessage(props.data.feedback[0]);
          setFeedback('Feedback-entrance');
        }
      }
      else {
        currentPairs[column][selection] = "incorrect";
        currentPairs[oppositeColumn][oppositeSelection] = "incorrect";
        setPairs(currentPairs);
        setPairSelections([null, null]);
      }

    }
  }
  
  function submitAnswer(selection) {
    if (feedback !== 'Feedback-entrance') {
      if (selection === props.data.correctAnswer) {
        setAnswer('correct')
      }
      else {
        setAnswer('incorrect');
      }
      setSelectedAnswer(selection);
      setFeedbackMessage(props.data.feedback[selection])
      setFeedback('Feedback-entrance')
    }
  }

  function checkAnswers() {
    if (feedback !== 'Feedback-entrance') {
      setAnswer('correct');
      setFeedbackMessage(props.data.feedback[0]);
      for (let i = 0; i < props.data.correctAnswers.length; i++) {
        if (multiSelections[i] !== props.data.correctAnswers[i]) {
          setAnswer('incorrect');
          setFeedbackMessage(props.data.feedback[1])
        }
      }
      setFeedback('Feedback-entrance')
    }
  }

  function checkSize() {
    if (size < props.data.correctAnswer[0]) {
      setAnswer('incorrect')
      setFeedbackMessage(props.data.feedback[0])
    }
    else if (size > props.data.correctAnswer[1]) {
      setAnswer('incorrect')
      setFeedbackMessage(props.data.feedback[2])
    }
    else {
      setAnswer('correct')
      setFeedbackMessage(props.data.feedback[1])
    }
    setFeedback('Feedback-entrance')
  }

  function closeFeedback() {
    if (answer === 'correct') {
      props.nextActivity()
      if (props.data.type === "multiSelect") {
        setMultiSelections([null]);
        setShowContinueButton(false);
      }
      else if (props.data.type === "pairs") {
        setPairs([[false, false, false, false, false], [false, false, false, false, false]]);
        setCorrectPairs(0);
      }
    }
    setSelectedAnswer(null);
    setFeedback('Feedback-exit')
  }

  function updateSize(newSize) {
    setSize(newSize);
    setShowContinueButton(true);
  }

  function submit(feedback) {
    if (feedback === 0) {
      setAnswer('correct');
    }
    else {
      setAnswer('incorrect')
    }
    setFeedbackMessage(props.data.feedback[feedback]);
    setFeedback('Feedback-entrance');
  }

  return (
    <div className="">
      <ActivityProgressBar 
        activity={props.activity} 
        total={props.total} 
        closeLesson={props.closeLesson} 
      />

      {props.data.card ?
        <ActivityCard 
          data={props.data} 
          activity={props.activity}
          lesson={props.lesson}
          topic={props.topic}
        />
        : null}

      <h4>{props.data.title}</h4>

      {props.data.titleImage ? 
        <TitleImage 
          data={props.data} 
          activity={props.activity}
          lesson={props.lesson}
          topic={props.topic}
        /> 
        : null}


      {props.data.instructions ? <p className="Activity-instructions">{props.data.instructions}</p> : null}

      {props.data.type === "editor" ? 
        <Editor 
          data={props.data}
          submit={submit}
          activity={props.activity}
          topic={props.topic}
          size={size}
        />
        : null}

      {props.data.type === "slider" ? 
        <>
          <Frame 
            element={props.data.element}
            size={size}
            vMargin={"12px"}
            hMargin={"12px"}
          />
          <Slider 
            size={size}
            updateSize={updateSize}
            range={props.data.sliderRange}
          />
          {showContinueButton ? 
            <Button 
              text="Continue" 
              relative={props.data.relative ? true : false} 
              continue={checkSize} 
            /> : null}
        </>
        : null}

      {props.data.type === "keyboard" ? 
        <Keyboard 
          keys={props.data.keys}
          data={props.data}
          finalLabel={(props.data.labels.length - 1)}
          lesson={props.lesson}
          activity={props.activity}
          topic={props.topic}
          submit={submit}
        />
        : null}

      {props.data.type === "static" ? 
        <Button 
          text="Got it" 
          relative={props.data.relative ? true : false} 
          continue={props.nextActivity} 
        /> 
        : null}
      {props.data.type === "pairs" ?
        <Pairs
          data={props.data}
          lesson={props.lesson}
          activity={props.activity}
          topic={props.topic}
          selectPair={selectPair}
          pairs={pairs}
          pairSelections={pairSelections}
        /> 
        : null}

      {props.data.options ? 
        <ActivityAnswers 
          options={props.data.options}
          data={props.data}
          lesson={props.lesson}
          selectAnswer={selectAnswer}
          activity={props.activity}
          topic={props.topic}
          selectedAnswer={selectedAnswer}
          multiSelections={multiSelections}
        />
         : null}

      {showContinueButton && (props.data.type === "multiSelect") ? 
        <Button 
          text="Continue" 
          relative={props.data.relative ? true : false} 
          continue={checkAnswers} 
        /> : null}

      <Feedback 
        feedback={feedback} 
        feedbackMessage={feedbackMessage}
        answer={answer} 
        closeFeedback={closeFeedback}
      />
    </div>
  );
}

export default Activity;
