import './App.css';

function CourseLevelImageBlock(props) {
  const color = (props.levelNumber >= props.column ? props.levelColors[props.levelNumber - 1] : "");
  return (
    <div className={"CourseLevelImageBlock " + color}></div>
  );
}

export default CourseLevelImageBlock;
